import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {

  return (
    <div
      id="navbar"
      className="flex items-center justify-center w-full px-4 py-4 border-b border-black "
    >
      <div
        id="navbar-container"
        className="flex items-center justify-between w-full"
      >
        <div
          id="mega-brand-logo"
          className="flex items-center justify-center max-[768px]:hidden w-80"
        >
          <Link to={"/"}>
            <img
              src="../../main-logo.png"
              alt="mega-brand-logo"
              className="w-[120px] max-[768px]:w-[100px] rounded-full transition ease-in-out delay-100 duration-200 hover:scale-105 border-black border"
            />
          </Link>
        </div>
        <div
          id="navbar-as-container"
          className="flex items-center justify-center w-[80%]"
        >
          <ul
            id="navbar-as-list"
            className="flex flex-wrap items-center justify-start gap-5 text-lg text-black max-[768px]:text-base  "
          >
            <li className="py-1 ">
              <Link
                to="/"
                className="font-bold transition duration-150 ease-in-out delay-150 border-b border-black "
              >
                HOME
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/luxury-collection"
                className="font-bold transition duration-150 ease-in-out delay-150 border-b border-black "
              >
                LUXURY SUMMER COLLECTION
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/bareeze-collection"
                className="font-bold transition duration-150 ease-in-out delay-150 border-b border-black "
              >
                BAREEZE SUMMER COLLECTION
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/two-piece"
                className="font-bold transition duration-150 ease-in-out delay-150 border-b border-black "
              >
                2 PIECE COLLECTION
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/summer-collection"
                className="font-bold transition duration-150 ease-in-out delay-150 border-b border-black "
              >
                SUMMER COLLECTION
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/lawn-collection"
                className="font-bold transition duration-150 ease-in-out delay-150 border-b border-black "
              >
                LAWN COLLECTION
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}