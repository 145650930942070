export const homeData = [
    //  Luxury Collection

    {
        id: 1,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6024,
        image: "../../assets/Luxury Collection/LSC29.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 2,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6024,
        image: "../../assets/Luxury Collection/LSC30.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 3,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6028,
        image: "../../assets/Luxury Collection/LSC35.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 4,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6024,
        image: "../../assets/Luxury Collection/LSC36.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 5,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6043,
        image: "../../assets/Luxury Collection/LSC23.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 6,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6043,
        image: "../../assets/Luxury Collection/LSC24.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 7,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6037,
        image: "../../assets/Luxury Collection/LSC19.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 8,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6037,
        image: "../../assets/Luxury Collection/LSC20.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 9,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5598 - 2,
        image: "../../assets/Luxury Collection/LSC17.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 10,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5898 - 2,
        image: "../../assets/Luxury Collection/LSC18.jpeg",
        price: "Rs 3,000.00",
    },
    // Bareeze Summer Collection
    {
        id: 11,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B1.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 12,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B2.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 13,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 3,
        image: "../../assets/Bareeze Collection/B3.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 14,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 3,
        image: "../../assets/Bareeze Collection/B4.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 15,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 5,
        image: "../../assets/Bareeze Collection/B5.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 16,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 5,
        image: "../../assets/Bareeze Collection/B6.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 17,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 7,
        image: "../../assets/Bareeze Collection/B7.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 18,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 7,
        image: "../../assets/Bareeze Collection/B8.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 19,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B9.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 20,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B10.jpg",
        price: "Rs 2,700.00",
    },
    // Two Piece  Collection
    {
        id: 21,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 6,
        image: "../../assets/2 piece/6.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 22,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 7,
        image: "../../assets/2 piece/7.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 23,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 8,
        image: "../../assets/2 piece/31.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 24,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 9,
        image: "../../assets/2 piece/93.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 25,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 10,
        image: "../../assets/2 piece/117.JPG",
        price: "Rs 1,800.00",
    },
    // Summer  Collection
    {
        id: 26,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 1,
        image: "../../assets/Summer Collection/SC1.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 27,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 1,
        image: "../../assets/Summer Collection/SC2.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 28,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 3,
        image: "../../assets/Summer Collection/SC3.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 29,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 3,
        image: "../../assets/Summer Collection/SC4.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 30,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 5,
        image: "../../assets/Summer Collection/SC5.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 31,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 5,
        image: "../../assets/Summer Collection/SC6.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 32,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC7.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 33,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC8.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 34,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC25.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 35,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC26.jpeg",
        price: "Rs 2,650.00",
    },
]