import React from "react";
import { Link, useParams } from "react-router-dom";
import { LuxuryData } from "../../Data/LuxuryData";
import { FaWhatsapp } from "react-icons/fa";

const LuxuryDetail = () => {
  const { id } = useParams();
  const product = LuxuryData.find((p) => p.id === parseInt(id));

  return (
    <div
      id="product-detail"
      className="flex items-center justify-center w-full h-auto min-h-screen py-10 "
    >
      <div
        id="product-detail-container"
        className="flex items-center justify-center w-full px-4 lg:px-0"
      >
        {product ? (
          <div
            id="detail-card"
            className=" flex flex-col lg:flex-row items-center justify-around  w-full lg:w-[80%] shadow-lg  lg:p-8 "
          >
            <div className=" w-full lg:w-[50%] flex items-center justify-center">
              <img
                src={product.image}
                alt={product.name}
                className="object-contain mb-2"
              />
            </div>
            <div className=" flex flex-col items-start justify-start gap-6 lg:gap-12 w-full lg:w-[50%] mb-10 lg:mb-40 px-4 lg:pt-0">
              <span className="text-2xl font-bold text-center lg:text-4xl lg:text-left">
                {product.name}
              </span>
              <span className="text-lg text-red-700 lg:text-xl">
                {product.price}
              </span>
              <span className="text-xl font-semibold ">
                To Buy Press WhatsApp Button
              </span>
              <Link
                to={`https://wa.me/923126605140?text=Hello, %0AI'm interested in this product: %0A${product.name}, %0APrice: ${product.price},,%0ACode: ${product.code}`}
                target="_blank"
                className="flex items-center justify-center w-full gap-2 py-3 text-base font-semibold bg-green-500 rounded-lg lg:text-lg hover:bg-green-800"
              >
                <FaWhatsapp />
                <span>WhatsApp</span>
              </Link>
              <span className="flex flex-col gap-4 text-base font-medium lg:text-lg lg:gap-6">
                <p
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                />
                <p className="text-left lg:text-left">
                  Free Shipping All Over Pakistan <br />
                  Money Back Gurantee <br />
                  24 Hours Service
                </p>
              </span>
            </div>
          </div>
        ) : (
          <p>Product not found</p>
        )}
      </div>
    </div>
  );
};

export default LuxuryDetail;
