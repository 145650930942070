import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { homeData } from "../../Data/homeData";

export default function Home() {
  const navigate = useNavigate();

  const data = homeData.reverse()

  const handleProductClick = (id) => {
    navigate(`/product/${id + 1}`);
  };

  return (
    <div id="home">
      <div id="home-container">
        <div
          id="home-page-heading"
          className="flex flex-col items-center justify-center w-full py-8"
        >
          <span className=" max-[768px]:text-4xl text-5xl font-bold">
            MOST POPULAR
          </span>
          <span className="text-3xl border-b border-black">HOME PAGE</span>
        </div>
        {/* LUXURY SUMMER COLLECTION */}
        <div className="flex items-center text-center justify-between max-[768px]:justify-center px-24 text-xl font-semibold">
          <span>LUXURY SUMMER COLLECTION</span>
          <div
            id="all-in-one-button-container"
            className="flex items-center justify-center py-8 max-[768px]:hidden "
          >
            <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
              <Link to="/luxury-collection" className="text-lg">
                VIEW ALL
              </Link>
            </button>
          </div>
        </div>
        <div
          id="home-products-container"
          className="flex flex-wrap items-center justify-center w-full gap-10 py-10 "
        >
          {data.map((product) => {
            if (product.id % 2 === 1 && product.type === "luxury-collection") {
              return (
                <div
                  key={product.id}
                  onClick={() => handleProductClick(product.id)}
                  style={{ cursor: "pointer" }}
                  id="home-card"
                  className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  lg:h-[420px] w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
                >
                  <div
                    id="product-image"
                    className="w-full h-[75%] max-[768px]:w-full"
                  >
                    <img
                      src={product.image}
                      alt="product-image"
                      className="object-cover "
                    />
                  </div>
                  <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                    <span className="w-full max-h-full">
                      {product.name}
                    </span>
                    <span className="text-red-700">{product.price}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div
          id="all-in-one-button-container"
          className="hidden items-center justify-center py-8 max-[768px]:flex "
        >
          <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
            <Link to="/luxury-collection" className="text-lg">
              VIEW ALL
            </Link>
          </button>
        </div>
        {/* BAREEZE SUMMER COLLECTION */}
        <div className="flex items-center justify-between px-24 text-xl font-semibold text-center">
          <span className="text-center">BAREEZE SUMMER COLLECTION</span>
          <div
            id="all-in-one-button-container"
            className="flex items-center justify-center py-8 max-[768px]:hidden"
          >
            <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
              <Link to="/bareeze-collection" className="text-lg">
                VIEW ALL
              </Link>
            </button>
          </div>
        </div>
        <div
          id="home-products-container"
          className="flex flex-wrap items-center justify-center w-full gap-10 py-10 "
        >
          {data.map((product) => {
            if (product.id % 2 === 1 && product.type === "bareeze-collection") {
              return (
                <div
                  key={product.id}
                  onClick={() => handleProductClick(product.id)}
                  style={{ cursor: "pointer" }}
                  id="home-card"
                  className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  lg:h-[420px] w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
                >
                  <div
                    id="product-image"
                    className="w-full h-[75%] max-[768px]:w-full"
                  >
                    <img
                      src={product.image}
                      alt="product-image"
                      className="object-cover "
                    />
                  </div>
                  <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                    <span className="w-full max-h-full">
                      {product.name}
                    </span>
                    <span className="text-red-700">{product.price}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div
          id="all-in-one-button-container"
          className="hidden items-center justify-center py-8 max-[768px]:flex  "
        >
          <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
            <Link to="/bareeze-collection" className="text-lg">
              VIEW ALL
            </Link>
          </button>
        </div>
        {/* Two Piece Collection */}
        <div className="flex items-center justify-between px-24 text-xl font-semibold text-center">
          <span>2 PIECE COLLECTION</span>
          <div
            id="all-in-one-button-container"
            className="flex items-center justify-center py-8 max-[768px]:hidden"
          >
            <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
              <Link to="/two-piece" className="text-lg">
                VIEW ALL
              </Link>
            </button>
          </div>
        </div>
        <div
          id="home-products-container"
          className="flex flex-wrap items-center justify-center w-full gap-10 py-10 "
        >
          {data.map((product) => {
            if (product.type === "two-piece") {
              return (
                <div
                  key={product.id}
                  onClick={() => handleProductClick(product.id - 1)}
                  style={{ cursor: "pointer" }}
                  id="home-card"
                  className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  lg:h-[420px] w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
                >
                  <div
                    id="product-image"
                    className="w-full h-[75%] max-[768px]:w-full"
                  >
                    <img
                      src={product.image}
                      alt="product-image"
                      className="object-cover "
                    />
                  </div>
                  <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                    <span className="w-full max-h-full">
                      {product.name} <br /> (PREMIUM FABRIC)
                    </span>
                    <span className="text-red-700">{product.price}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div
          id="all-in-one-button-container"
          className="hidden items-center justify-center py-8 max-[768px]:flex  "
        >
          <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
            <Link to="/two-piece" className="text-lg">
              VIEW ALL
            </Link>
          </button>
        </div>
        {/* Summer Collection */}
        <div className="flex items-center text-center justify-between max-[768px]:justify-center px-24 text-xl font-semibold">
          <span>SUMMER COLLECTION</span>
          <div
            id="all-in-one-button-container"
            className="flex items-center justify-center py-8 max-[768px]:hidden "
          >
            <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
              <Link to="/summer-collection" className="text-lg">
                VIEW ALL
              </Link>
            </button>
          </div>
        </div>
        <div
          id="home-products-container"
          className="flex flex-wrap items-center justify-center w-full gap-10 py-10 "
        >
          {data.map((product) => {
            if (product.type === "summer-collection") {
              return (
                <div
                  key={product.id}
                  onClick={() => handleProductClick(product.id)}
                  style={{ cursor: "pointer" }}
                  id="home-card"
                  className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  lg:h-[420px] w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
                >
                  <div
                    id="product-image"
                    className="w-full h-[75%] max-[768px]:w-full"
                  >
                    <img
                      src={product.image}
                      alt="product-image"
                      className="object-cover "
                    />
                  </div>
                  <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                    <span className="w-full max-h-full">
                      {product.name}
                    </span>
                    <span className="text-red-700">{product.price}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div
          id="all-in-one-button-container"
          className="hidden items-center justify-center py-8 max-[768px]:flex "
        >
          <button className="px-8 py-4 text-white transition duration-200 ease-in-out delay-75 bg-black hover:text-black hover:outline hover:bg-transparent hover:outline-black">
            <Link to="/summer-collection" className="text-lg">
              VIEW ALL
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}
