export const BareezeData = [
    {
        id: 1,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B1.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 2,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B2.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 3,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 3,
        image: "../../assets/Bareeze Collection/B3.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 4,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 3,
        image: "../../assets/Bareeze Collection/B4.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 5,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 5,
        image: "../../assets/Bareeze Collection/B5.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 6,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 5,
        image: "../../assets/Bareeze Collection/B6.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 7,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 7,
        image: "../../assets/Bareeze Collection/B7.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 8,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 7,
        image: "../../assets/Bareeze Collection/B8.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 9,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B9.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 10,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B10.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 11,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 11,
        image: "../../assets/Bareeze Collection/B11.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 12,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 11,
        image: "../../assets/Bareeze Collection/B12.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 13,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B13.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 14,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 1,
        image: "../../assets/Bareeze Collection/B14.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 15,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 15,
        image: "../../assets/Bareeze Collection/B15.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 16,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 15,
        image: "../../assets/Bareeze Collection/B16.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 17,
        type: "bareeze-collection",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 17,
        image: "../../assets/Bareeze Collection/B17.jpg",
        price: "Rs 2,700.00",
    },
    {
        id: 18,
        type: "bareeze-collection-dp",
        name: "BAREEZE SUMMER COLLECTION",
        description:
            "Summer Collection 2025</br>bareeze-collection (Day To Day)</br>(Premium Lawn)</br>DIGITAL PRINTED 3PC</br>Quality (90/70)</br>Front Back Sleeves Digital Printed </br>Digital printed Trouser </br>Digital printed Lawn dupatta",
        code: 17,
        image: "../../assets/Bareeze Collection/B18.jpg",
        price: "Rs 2,700.00",
    },
]