import "./App.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Headline from "./components/Headline/Headline";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import LuxuryCollection from "./components/Luxury/LuxuryCollection";
import TwoPiece from "./components/TwoPiece/twoPieceCollection";
import LuxuryDetail from "./components/Luxury/LuxuryDetail";
import BareezeDetail from "./components/Bareeze/BareezeDetail";
import TwoPieceDetail from "./components/TwoPiece/TwoPieceDetail";
import WhyUs from "./components/Whyus/WhyUs";
import Footer from "./components/Footer/Footer";
import BareezeCollection from "./components/Bareeze/BareezeCollection";
import HomeDetail from "./components/Home/HomeDetail";
import ScrollToTop from "./components/Scroll/ScrollToTop";
import SummerCollection from "./components/Summer/SummerCollection";
import SummerDetail from "./components/Summer/SummerDetail";
import LawnCollection from "./components/Lawn/LawnCollection";
import LawnDetail from "./components/Lawn/LawnDetail";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Headline />
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/luxury-collection" element={<LuxuryCollection />} />
          <Route path="/bareeze-collection" element={<BareezeCollection />} />
          <Route path="/two-piece" element={<TwoPiece />} />
          <Route path="/luxury/:id" element={<LuxuryDetail />} />
          <Route path="/product/:id" element={<HomeDetail />} />
          <Route path="/bareeze-collection/:id" element={<BareezeDetail />} />
          <Route path="/two-piece/:id" element={<TwoPieceDetail />} />
          <Route path="/summer-collection" element={<SummerCollection />} />
          <Route path="/summer-collection/:id" element={<SummerDetail />} />
          <Route path="/lawn-collection" element={<LawnCollection />} />
          <Route path="/lawn/:id" element={<LawnDetail />} />
        </Routes>
      </main>
      <WhyUs />
      <div className="fixed bottom-0 right-0 z-50 w-40 p-4 ">
        <Link
          to="https://wa.me/923126605140?text=Can i get more details ? "
          target="_blank"
        >
          <img src="../../whatsapp-logo.png" alt="whatsapp-logo" />
        </Link>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
