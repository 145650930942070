export const TwoPieceData = [
    {
        id: 1,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 1,
        image: "../../assets/2 piece/1.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 2,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 2,
        image: "../../assets/2 piece/2.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 3,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 3,
        image: "../../assets/2 piece/3.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 4,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 4,
        image: "../../assets/2 piece/4.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 5,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 5,
        image: "../../assets/2 piece/5.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 6,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 6,
        image: "../../assets/2 piece/6.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 7,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 7,
        image: "../../assets/2 piece/7.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 8,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 8,
        image: "../../assets/2 piece/8.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 9,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 9,
        image: "../../assets/2 piece/9.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 10,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 10,
        image: "../../assets/2 piece/10.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 11,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 11,
        image: "../../assets/2 piece/11.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 12,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 12,
        image: "../../assets/2 piece/12.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 13,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 13,
        image: "../../assets/2 piece/13.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 14,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 14,
        image: "../../assets/2 piece/14.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 15,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 15,
        image: "../../assets/2 piece/15.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 16,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 16,
        image: "../../assets/2 piece/16.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 17,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 17,
        image: "../../assets/2 piece/17.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 18,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 18,
        image: "../../assets/2 piece/18.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 19,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 19,
        image: "../../assets/2 piece/19.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 20,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 20,
        image: "../../assets/2 piece/20.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 21,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 21,
        image: "../../assets/2 piece/21.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 22,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 22,
        image: "../../assets/2 piece/22.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 23,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 23,
        image: "../../assets/2 piece/23.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 24,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 24,
        image: "../../assets/2 piece/24.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 25,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 25,
        image: "../../assets/2 piece/25.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 26,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 26,
        image: "../../assets/2 piece/26.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 27,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 27,
        image: "../../assets/2 piece/27.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 28,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 28,
        image: "../../assets/2 piece/28.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 29,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 29,
        image: "../../assets/2 piece/29.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 30,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 30,
        image: "../../assets/2 piece/30.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 31,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 31,
        image: "../../assets/2 piece/31.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 32,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 32,
        image: "../../assets/2 piece/32.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 33,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 33,
        image: "../../assets/2 piece/33.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 34,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 34,
        image: "../../assets/2 piece/34.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 35,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 35,
        image: "../../assets/2 piece/35.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 36,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 36,
        image: "../../assets/2 piece/36.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 37,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 37,
        image: "../../assets/2 piece/37.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 38,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 38,
        image: "../../assets/2 piece/38.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 39,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 39,
        image: "../../assets/2 piece/39.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 40,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 40,
        image: "../../assets/2 piece/40.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 41,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 41,
        image: "../../assets/2 piece/41.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 42,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 42,
        image: "../../assets/2 piece/42.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 43,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 43,
        image: "../../assets/2 piece/43.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 44,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 44,
        image: "../../assets/2 piece/44.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 45,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 45,
        image: "../../assets/2 piece/45.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 46,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 46,
        image: "../../assets/2 piece/46.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 47,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 47,
        image: "../../assets/2 piece/47.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 48,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 48,
        image: "../../assets/2 piece/48.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 49,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 49,
        image: "../../assets/2 piece/49.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 50,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 50,
        image: "../../assets/2 piece/50.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 51,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 51,
        image: "../../assets/2 piece/51.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 52,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 52,
        image: "../../assets/2 piece/52.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 53,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 53,
        image: "../../assets/2 piece/53.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 54,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 54,
        image: "../../assets/2 piece/54.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 55,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 55,
        image: "../../assets/2 piece/55.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 56,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 56,
        image: "../../assets/2 piece/56.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 57,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 57,
        image: "../../assets/2 piece/57.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 58,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 58,
        image: "../../assets/2 piece/58.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 59,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 59,
        image: "../../assets/2 piece/59.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 60,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 60,
        image: "../../assets/2 piece/60.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 61,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 61,
        image: "../../assets/2 piece/61.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 62,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 62,
        image: "../../assets/2 piece/62.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 63,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 63,
        image: "../../assets/2 piece/63.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 64,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 64,
        image: "../../assets/2 piece/64.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 65,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 65,
        image: "../../assets/2 piece/65.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 66,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 66,
        image: "../../assets/2 piece/66.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 67,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 67,
        image: "../../assets/2 piece/67.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 68,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 68,
        image: "../../assets/2 piece/68.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 69,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 69,
        image: "../../assets/2 piece/69.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 70,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 70,
        image: "../../assets/2 piece/70.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 71,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 71,
        image: "../../assets/2 piece/71.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 72,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 72,
        image: "../../assets/2 piece/72.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 73,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 73,
        image: "../../assets/2 piece/73.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 74,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 74,
        image: "../../assets/2 piece/74.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 75,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 75,
        image: "../../assets/2 piece/75.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 76,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 76,
        image: "../../assets/2 piece/76.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 77,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 77,
        image: "../../assets/2 piece/77.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 78,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 78,
        image: "../../assets/2 piece/78.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 79,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 79,
        image: "../../assets/2 piece/79.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 80,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 80,
        image: "../../assets/2 piece/80.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 81,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 81,
        image: "../../assets/2 piece/81.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 82,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 82,
        image: "../../assets/2 piece/82.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 83,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 83,
        image: "../../assets/2 piece/83.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 84,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 84,
        image: "../../assets/2 piece/84.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 85,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 85,
        image: "../../assets/2 piece/85.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 86,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 86,
        image: "../../assets/2 piece/86.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 87,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 87,
        image: "../../assets/2 piece/87.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 88,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 88,
        image: "../../assets/2 piece/88.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 89,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 89,
        image: "../../assets/2 piece/89.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 90,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 90,
        image: "../../assets/2 piece/90.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 91,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 91,
        image: "../../assets/2 piece/91.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 92,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 92,
        image: "../../assets/2 piece/92.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 93,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 93,
        image: "../../assets/2 piece/93.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 94,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 94,
        image: "../../assets/2 piece/94.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 95,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 95,
        image: "../../assets/2 piece/95.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 96,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 96,
        image: "../../assets/2 piece/96.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 97,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 97,
        image: "../../assets/2 piece/97.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 98,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 98,
        image: "../../assets/2 piece/98.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 99,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 99,
        image: "../../assets/2 piece/99.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 100,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 100,
        image: "../../assets/2 piece/100.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 101,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 101,
        image: "../../assets/2 piece/101.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 102,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 102,
        image: "../../assets/2 piece/102.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 103,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 103,
        image: "../../assets/2 piece/103.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 104,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 104,
        image: "../../assets/2 piece/104.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 105,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 105,
        image: "../../assets/2 piece/105.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 106,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 106,
        image: "../../assets/2 piece/106.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 107,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 107,
        image: "../../assets/2 piece/107.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 108,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 108,
        image: "../../assets/2 piece/108.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 109,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 109,
        image: "../../assets/2 piece/109.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 110,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 110,
        image: "../../assets/2 piece/110.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 111,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 111,
        image: "../../assets/2 piece/111.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 112,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 112,
        image: "../../assets/2 piece/112.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 113,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 113,
        image: "../../assets/2 piece/113.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 114,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 114,
        image: "../../assets/2 piece/114.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 115,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 115,
        image: "../../assets/2 piece/115.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 116,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 116,
        image: "../../assets/2 piece/116.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 117,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 117,
        image: "../../assets/2 piece/117.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 118,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 118,
        image: "../../assets/2 piece/118.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 119,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 119,
        image: "../../assets/2 piece/119.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 120,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 120,
        image: "../../assets/2 piece/120.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 121,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 121,
        image: "../../assets/2 piece/121.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 122,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 122,
        image: "../../assets/2 piece/122.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 123,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 123,
        image: "../../assets/2 piece/123.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 124,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 124,
        image: "../../assets/2 piece/124.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 125,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 125,
        image: "../../assets/2 piece/125.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 126,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 126,
        image: "../../assets/2 piece/126.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 127,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 127,
        image: "../../assets/2 piece/127.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 128,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 128,
        image: "../../assets/2 piece/128.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 129,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 129,
        image: "../../assets/2 piece/129.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 130,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 130,
        image: "../../assets/2 piece/130.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 131,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 131,
        image: "../../assets/2 piece/131.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 132,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 132,
        image: "../../assets/2 piece/132.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 133,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 133,
        image: "../../assets/2 piece/133.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 134,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 134,
        image: "../../assets/2 piece/134.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 135,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 135,
        image: "../../assets/2 piece/135.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 136,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 136,
        image: "../../assets/2 piece/136.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 137,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 137,
        image: "../../assets/2 piece/137.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 138,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 138,
        image: "../../assets/2 piece/138.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 139,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 139,
        image: "../../assets/2 piece/139.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 140,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 140,
        image: "../../assets/2 piece/140.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 141,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 141,
        image: "../../assets/2 piece/141.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 142,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 142,
        image: "../../assets/2 piece/142.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 143,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 143,
        image: "../../assets/2 piece/143.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 144,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 144,
        image: "../../assets/2 piece/144.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 145,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 145,
        image: "../../assets/2 piece/145.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 146,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 146,
        image: "../../assets/2 piece/146.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 147,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 147,
        image: "../../assets/2 piece/147.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 148,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 148,
        image: "../../assets/2 piece/148.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 149,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 149,
        image: "../../assets/2 piece/149.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 150,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 150,
        image: "../../assets/2 piece/150.JPG",
        price: "Rs 1,800.00",
    },
    {
        id: 151,
        type: "two-piece",
        name: "2 PIECE COLLECTION",
        description:
            "2 Piece Collection 2025</br>Brand: BONANZA</br>Category: Lawn 2PC</br>Premium Quality 90/88 Airjet Lawn</br>Digital Printed Lawn Shirt</br>Digital Printed Back & Sleeves</br>Dyed Plain Trouser (PREMIUM QUALITY)</br>ORIGINAL FABRIC BY STEMP</br>COLOUR AND FABRIC GUARANTEED",
        code: 151,
        image: "../../assets/2 piece/151.JPG",
        price: "Rs 1,800.00",
    },
]