import React from "react";
import { FaHandHoldingHeart, FaMoneyBill1Wave } from "react-icons/fa6";
import { LiaShippingFastSolid } from "react-icons/lia";

export default function WhyUs() {
  return (
    <div
      id="why-us"
      className="flex flex-col items-center justify-center w-full"
    >
      <div
        id="why-us-heading-container"
        className="flex items-center justify-center w-full py-4 text-4xl font-semibold text-centertext-center "
      >
        <span id="why-us-heading">WHY US?</span>
      </div>
      <div
        id="services-container"
        className="flex flex-wrap items-center justify-center "
      >
        <div
          id="first-service"
          className="flex flex-col items-center justify-center gap-4 h-80 w-96"
        >
          <span>
            <LiaShippingFastSolid size={60} />
          </span>
          <span className="text-3xl font-semibold text-center">
            Free Shipping
          </span>
          <span className="text-base ">Free Shipping On All Orders</span>
        </div>
        <div
          id="second-service"
          className="flex flex-col items-center justify-center h-40 gap-4 w-96"
        >
          <span>
            <FaHandHoldingHeart size={60} />
          </span>
          <span className="text-3xl font-semibold text-center">
            Free Return
          </span>
          <span className="text-base ">Cash On Delivery</span>
        </div>
        <div
          id="third-service"
          className="flex flex-col items-center justify-center gap-4 h-80 w-96"
        >
          <span>
            {" "}
            <FaMoneyBill1Wave size={60} />
          </span>
          <span className="text-3xl font-semibold text-center">
            Money Back Guarantee
          </span>
          <span className="text-base ">We Support Online 24 Hours</span>
        </div>
      </div>
    </div>
  );
}
