import React from "react";
import { SummerData } from "../../Data/SummerData";
import { useNavigate } from "react-router-dom";

function SummerCollection() {
    const navigate = useNavigate();

    const handleProductClick = (id) => {
        navigate(`/summer-collection/${id + 1}`);
    };
    return (
        <div>
            <div id="summer-collection">
                <div
                    id="summer-collection-heading-container"
                    className="w-full flex items-center justify-center max-[768px]:text-4xl text-5xl py-8 font-bold"
                >
                    <span className="text-center">SUMMER COLLECTION</span>
                </div>
                <div
                    id="summer-collection-container"
                    className="flex flex-wrap items-center justify-center w-full gap-10 py-10 "
                >
                    {SummerData.slice().reverse().map((product) => {
                        if (product.type === "summer-collection") {
                            return (
                                <div
                                    key={product.id}
                                    onClick={() => handleProductClick(product.id)}
                                    style={{ cursor: "pointer" }}
                                    id="home-card"
                                    className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  lg:h-[420px] w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
                                >
                                    <div
                                        id="product-image"
                                        className="w-full h-[75%] max-[768px]:w-full"
                                    >
                                        <img
                                            src={product.image}
                                            alt="product-image"
                                            className="object-cover "
                                        />
                                    </div>
                                    <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                                        <span className="w-full max-h-full">
                                            {product.name}
                                        </span>
                                        <span className="text-red-700">{product.price}</span>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

export default SummerCollection;
