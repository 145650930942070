import React from "react";

export default function Headline() {
  return (
    <div
      id="headiline"
      className="flex items-center justify-center w-full bg-blue-900"
    >
      <div
        id="headline-container"
        className="flex flex-wrap items-center justify-center px-2 py-1 text-base text-center text-white"
      >
        Cash on Delivery & Free shipping All Over Pakistan <br /> 0312-6605140
      </div>
    </div>
  );
}
