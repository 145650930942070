export const SummerData = [
    {
        id: 1,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 1,
        image: "../../assets/Summer Collection/SC1.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 2,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 1,
        image: "../../assets/Summer Collection/SC2.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 3,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 3,
        image: "../../assets/Summer Collection/SC3.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 4,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 3,
        image: "../../assets/Summer Collection/SC4.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 5,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 5,
        image: "../../assets/Summer Collection/SC5.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 6,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 5,
        image: "../../assets/Summer Collection/SC6.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 7,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC7.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 8,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC8.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 9,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC9.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 10,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC10.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 11,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC11.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 12,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC12.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 13,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC13.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 14,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC14.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 15,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC15.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 16,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC16.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 17,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC17.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 18,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC18.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 19,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC19.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 20,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC20.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 21,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC21.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 22,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC22.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 23,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC23.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 24,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC24.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 25,
        type: "summer-collection",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC25.jpeg",
        price: "Rs 2,650.00",
    },
    {
        id: 26,
        type: "summer-collection-dp",
        name: "SUMMER COLLECTION",
        description:
            "Lawn 3pc</br> Front Full Embroidered</br>DAMAN EMBROIDERED</br>Plain Back</br>Embroidered Sleeves</br>Plain Lawn Trouser</br>DIGITAL PRINTED VOIL LAWN DUPATTA",
        code: 7,
        image: "../../assets/Summer Collection/SC26.jpeg",
        price: "Rs 2,650.00",
    },
]
