export const LawnData = [
    {
        id: 1,
        type: "lawn-collection",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Printed lawn back</br>Printed lawe sleeves</br>chiffon Printed dupta</br>Printed cambric trozer",
        code: 6024,
        image: "../../assets/Lawn Collection/LC1.jpeg",
        price: "Rs 3,300.00",
    },
    {
        id: 2,
        type: "lawn-collection-dp",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Printed lawn back</br>Printed lawe sleeves</br>chiffon Printed dupta</br>Printed cambric trozer",
        code: 6024,
        image: "../../assets/Lawn Collection/LC2.jpeg",
        price: "Rs 3,300.00",
    },
    {
        id: 3,
        type: "lawn-collection",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered sleeves bunches</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 6024,
        image: "../../assets/Lawn Collection/LC3.jpeg",
        price: "Rs 3,300.00",
    },
    {
        id: 4,
        type: "lawn-collection-dp",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered sleeves bunches</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 6024,
        image: "../../assets/Lawn Collection/LC4.jpeg",
        price: "Rs 3,300.00",
    },
    {
        id: 5,
        type: "lawn-collection",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered sleeves bunches</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 6024,
        image: "../../assets/Lawn Collection/LC5.jpeg",
        price: "Rs 3,500.00",
    },
    {
        id: 6,
        type: "lawn-collection-dp",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered sleeves bunches</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 6024,
        image: "../../assets/Lawn Collection/LC6.jpeg",
        price: "Rs 3,500.00",
    },
    {
        id: 7,
        type: "lawn-collection",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Digital Printed 90/70 Lawn Front</br>Embroidered Neckline Patch</br>Front Embroidered Daman Lace</br>Sleeves Embroidered Lace</br>Digital Printed 90/70 Lawn Back</br>Digital Printed 90/70 Lawn Sleeves</br>Digital Printed Embroidered Organza Dupatta</br>Digital Printed Trouser",
        code: 6024,
        image: "../../assets/Lawn Collection/LC7.jpeg",
        price: "Rs 3,500.00",
    },
    {
        id: 8,
        type: "lawn-collection-dp",
        name: "LAWN SUMMER COLLECTION",
        description:
            "Digital Printed 90/70 Lawn Front</br>Embroidered Neckline Patch</br>Front Embroidered Daman Lace</br>Sleeves Embroidered Lace</br>Digital Printed 90/70 Lawn Back</br>Digital Printed 90/70 Lawn Sleeves</br>Digital Printed Embroidered Organza Dupatta</br>Digital Printed Trouser",
        code: 6024,
        image: "../../assets/Lawn Collection/LC8.jpeg",
        price: "Rs 3,500.00",
    },
]