import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div
      id="footer"
      className="flex items-center justify-center w-full p-10 text-lg text-white bg-blue-900 border-t "
    >
      <div
        id="footer-container"
        className="flex flex-col items-center justify-center gap-10 text-center"
      >
        <div className="flex items-center justify-center lg:gap-20" >
          <div
            id="mega-brand-logo"
            className="flex items-center justify-center "
          >
            <Link to={"/"}>
              <img
                src="../../main-logo.png"
                alt="mega-brand-logo"
                className="w-[120px] max-[768px]:w-[100px] rounded-full transition ease-in-out delay-100 duration-200 hover:scale-105 border-black border"
              />
            </Link>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-6 ">
            <span className="p-4 transition duration-200 ease-in-out delay-100 border rounded-full hover:scale-125 hover:border-black">
              <Link to="https://whatsapp.com/channel/0029Vb043xr3LdQUONMspO1K">
                <FaWhatsapp size={32} />
              </Link>
            </span>
            <span className="p-4 transition duration-200 ease-in-out delay-100 border rounded-full hover:scale-125 hover:border-black">
              <Link to="https://www.tiktok.com/@sbfabricsladycollection?lang=en">
                <FaTiktok size={32} />
              </Link>
            </span>
            <span className="p-4 transition duration-200 ease-in-out delay-100 border rounded-full hover:scale-125 hover:border-black">
              <Link to="https://www.facebook.com/SBFabricsLadyCollection">
                <FaFacebook size={32} />
              </Link>
            </span>
            <span className="p-4 transition duration-200 ease-in-out delay-100 border rounded-full hover:scale-125 hover:border-black">
              <Link to="https://www.instagram.com/sb_fabrics_ladycollection/?hl=en">
                <FaInstagram size={32} />
              </Link>
            </span>
          </div>
        </div>
        <div className="w-full h-0 border-b"></div>
        <div className="flex items-center justify-center w-full ">
          <span>Copyright © 2025, SB FABRICS</span>
        </div>
      </div>
    </div >
  );
}
