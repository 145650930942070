export const LuxuryData = [
    {
        id: 1,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6024,
        image: "../../assets/Luxury Collection/LSC1.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 2,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6024,
        image: "../../assets/Luxury Collection/LSC2.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 3,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6028,
        image: "../../assets/Luxury Collection/LSC3.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 4,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6024,
        image: "../../assets/Luxury Collection/LSC4.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 5,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6043,
        image: "../../assets/Luxury Collection/LSC5.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 6,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6043,
        image: "../../assets/Luxury Collection/LSC6.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 7,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6037,
        image: "../../assets/Luxury Collection/LSC7.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 8,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6037,
        image: "../../assets/Luxury Collection/LSC8.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 9,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5598 - 2,
        image: "../../assets/Luxury Collection/LSC9.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 10,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5898 - 2,
        image: "../../assets/Luxury Collection/LSC10.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 11,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5898 - 1,
        image: "../../assets/Luxury Collection/LSC11.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 12,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5898 - 1,
        image: "../../assets/Luxury Collection/LSC12.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 13,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5898 - 3,
        image: "../../assets/Luxury Collection/LSC13.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 14,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 5898 - 3,
        image: "../../assets/Luxury Collection/LSC14.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 15,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6001 - 2,
        image: "../../assets/Luxury Collection/LSC15.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 16,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6001 - 2,
        image: "../../assets/Luxury Collection/LSC16.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 17,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6001 - 1,
        image: "../../assets/Luxury Collection/LSC17.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 18,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6001 - 1,
        image: "../../assets/Luxury Collection/LSC18.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 19,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 8008,
        image: "../../assets/Luxury Collection/LSC19.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 20,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 8008,
        image: "../../assets/Luxury Collection/LSC20.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 21,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 8004,
        image: "../../assets/Luxury Collection/LSC21.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 22,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 8004,
        image: "../../assets/Luxury Collection/LSC22.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 23,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6008,
        image: "../../assets/Luxury Collection/LSC23.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 24,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6008,
        image: "../../assets/Luxury Collection/LSC24.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 25,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6009,
        image: "../../assets/Luxury Collection/LSC25.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 26,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 6009,
        image: "../../assets/Luxury Collection/LSC26.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 27,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC27.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 28,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC28.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 29,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC29.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 30,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC30.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 31,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC31.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 32,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC32.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 33,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC33.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 34,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC34.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 35,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC35.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 36,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC36.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 37,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC37.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 38,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC38.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 39,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC39.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 40,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC40.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 41,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC41.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 42,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC42.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 43,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC43.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 44,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC44.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 45,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC45.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 46,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC46.jpeg",
        price: "Rs 3,000.00",
    },
    {
        id: 47,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC47.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 48,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC48.jpeg",
        price: "Rs 3,050.00",
    },
    {
        id: 49,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC49.jpeg",
        price: "Rs 3,200.00",
    },
    {
        id: 50,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC50.jpeg",
        price: "Rs 3,200.00",
    },
    {
        id: 51,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC51.jpeg",
        price: "Rs 3,200.00",
    },
    {
        id: 52,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC52.jpeg",
        price: "Rs 3,200.00",
    },
    {
        id: 53,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC53.jpeg",
        price: "Rs 2,900.00",
    },
    {
        id: 54,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "90/70 Lawn Stuff</br>Front Full Heavy Embroidery</br>Sleeves Hheavy Embroidered</br>Trouser Plain</br>Bamber Chiffon Full Heavy Embroidery Dupta",
        code: 111,
        image: "../../assets/Luxury Collection/LSC54.jpeg",
        price: "Rs 2,900.00",
    },
    {
        id: 55,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline with embroidered spengle work daman boarder</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered spengle work sleeves boarder</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 111,
        image: "../../assets/Luxury Collection/LSC55.jpeg",
        price: "Rs 3,500.00",
    },
    {
        id: 56,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline with embroidered spengle work daman boarder</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered spengle work sleeves boarder</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 111,
        image: "../../assets/Luxury Collection/LSC56.jpeg",
        price: "Rs 3,500.00",
    },
{
        id: 57,
        type: "luxury-collection",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline with embroidered spengle work daman boarder</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered spengle work sleeves boarder</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 111,
        image: "../../assets/Luxury Collection/LSC57.jpeg",
        price: "Rs 3,500.00",
    },
    {
        id: 58,
        type: "luxury-collection-dp",
        name: "LUXURY SUMMER COLLECTION",
        description:
            "Printed lawn front with heavy embroidered spengle work neckline with embroidered spengle work daman boarder</br>Printed lawn back</br>Printed lawn sleeves with heavy embroidered spengle work sleeves boarder</br>Printed chiffon dupatta</br>printed cambric trouser",
        code: 111,
        image: "../../assets/Luxury Collection/LSC58.jpeg",
        price: "Rs 3,500.00",
    },
]